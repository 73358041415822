<template>
    <article>
        <h1>北京蓝灯鱼智能科技有限公司</h1>
        <h1>法律声明及隐私权政策</h1>
        <h2>提示条款</h2>
        <p>欢迎您使用“蓝灯鱼“的产品及/或服务！我们深知用户信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于确保用户信息及隐私的安全性。基于此，北京蓝灯鱼智能科技有限公司（以下或简称<b>“我们”</b>）特制定本《法律声明和隐私政策》（以下或简称 <b>“本政策”</b>），帮助您充分了解在您使用我们产品及/或服务的过程中，我们会如何收集、使用、共享、存储和保护您的用户信息以及您可以如何管理您的用户信息，以便您更好地作出适当的选择。
        </p>
        <p>在您开始使用我们的产品及/或服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以 <b>粗体/粗体下划线</b>标识的条款，确保您充分理解和同意之后再开始使用。除本政策外，<b>在特定场景下，我们还会通过即时告知（含弹窗、页面提示等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力</b>。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
        </p>
      <p>
        <b>您同意本政策表示您已了解我们提供的产品及/或服务，以及相应产品及/或服务功能运行所需的必要用户信息（请特别注意，本政策所述用户信息可能包含个人信息及/或敏感个人信息，具体请见“附录：相关定义”，以下不再赘述），并给予相应的收集使用授权。</b>
      </p>
        <h2>1、本政策的适用范围</h2>
        <p>1.1. 本政策适用于蓝灯鱼网站、小程序以及随技术发展出现的新形态（以下统称 <b>“蓝灯鱼应用”</b>）向您提供的各项产品和服务。若我们及关联公司向您提供的产品或服务内设有单独隐私政策或类似法律文件，该等单独的文件优先适用。</p>
      <p>1.2. <b>本政策不适用于其他第三方向您提供的服务，您的信息处理及保护事宜应当适用该等第三方的隐私权政策或类似文件，我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任。</b>例如，蓝灯鱼市场上的代理商或服务商依托蓝灯鱼产品向您提供服务时，您向其提供的信息不适用本政策。</p>
      <p>1.3. <b>需要特别说明的是，作为蓝灯鱼的用户，若您利用蓝灯鱼的技术服务，为您的用户再行提供服务，您应当依照相关法律要求另行与您的用户约定隐私权政策或类似法律文件</b>。</p>
      <h2>2、我们如何收集和使用您的用户信息</h2>
      <p>在您使用蓝灯鱼服务期间，我们可能通过以下三种方式获取您的信息：</p>
      <ul>
            <li>
              （1）您在使用我们产品及/或服务期间主动向我们提供的信息，如您或您授权的主体在注册账号、购买服务等情况下填写的相应信息；
            </li>
            <li>
              （2）在您使用我们的产品及/或服务期间，我们主动收集的相关信息，如在您使用蓝灯鱼应用程序的操作记录或其他日志类数据等；
            </li>
            <li>
              （3）在特殊场景下，您可能会委托并授权我们的员工或者合作伙伴代您上传、提交相关信息，如您委托客服人员协助您完成信息上传、提交。
            </li>
        </ul>
      <p>在您使用蓝灯鱼应用时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
      <ul>
        <li>（1）为实现向您提供蓝灯鱼应用的基本功能或服务，您须授权我们收集、使用的必要的信息。 <b>如您拒绝提供相应信息，您将无法正常使用蓝灯鱼应用</b>。</li>
        <li>（2）为实现向您提供蓝灯鱼应用的附加功能或服务，您可选择单独同意或不同意我们收集、使用的信息。<b>如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但不会影响您使用蓝灯鱼应用的基本功能</b>。</li>
      </ul>
      <p>蓝灯鱼长期致力于打造多样的功能或服务以满足您的需求。我们在此特别提醒您：因蓝灯鱼应用向您提供的功能或服务种类众多，且不同用户选择使用的具体功能或服务范围存在差异，故基本功能或附加功能及对应收集使用的信息类型、范围会有所区别，请以具体的功能或服务为准。</p>


      <h2>3、为向您提供蓝灯鱼应用的基本功能或服务</h2>
      <h2>3.1. 帮助您成为我们的会员</h2>
        <p>3.1.1. 当您在蓝灯鱼应用创建账号时，您须向我们提供会员名，设置、确认您的<b>登录密码</b>，提供您在中华人民共和国境内、中国香港、中国澳门的手机号码。您提交的手机号码（“安全手机号码”或“账号联系人手机号码”）用于您注册、登录、绑定账号、密码找回时接收验证码，并且作为您与蓝灯鱼指定的联系方式之一，用于接收相关业务通知（如新品上线、服务变更等）。<b>如果您拒绝提供上述信息，您将无法注册蓝灯鱼账号，仅可以使用浏览、搜索服务。</b></p>
        <p>3.1.2. 基于我们与通信运营商的合作，当你选择使用“微信扫码”快速登录蓝灯鱼网站时，经过你的同意，我们会使用运营商提供的功能，将你设备中的手机号码作为你的注册、登录帐号并免于密码或动态验证码核验，为你提供快捷的注册、登录服务。<b>如果你不希望使用“微信扫码”功能，可以通过其他方式注册、登录蓝灯鱼。</b></p>
        <p>3.1.3. 如果您已经注册有微信账号，我们可能经您单独同意后从该等第三方处获取您的第三方账号信息（头像、昵称、手机号码及其他单独页面提示的信息），使您可以便捷地登录蓝灯鱼应用。该第三方账号将绑定蓝灯鱼账号，但蓝灯鱼届时仍会要求您提供第2.1.1.条所述的安全手机号码。<b>如您提供的安全手机号码与第三方账号中绑定的手机号码不同，蓝灯鱼将使用您另行提交手机号码作为安全手机号码。</b></p>
    <h2>3.2. 为向您提供技术服务</h2>
      <p>3.2.1. 根据中华人民共和国法律法规规定，在您使用具体的技术服务时，您可能需要通过您的蓝灯鱼账号提供您的真实身份信息。蓝灯鱼为您提供了多种方式以协助您提供真实身份信息，您应根据您的身份属性选择适合的方式：</p>
      <ul>
        <li>（1）如您通过支付宝实名认证来提供您的真实信息，您需先行输入 <b>支付宝账号以及密码</b>登录支付宝账号，通过将支付宝账号绑定您的蓝灯鱼账号完成实名认证，即您授权支付宝向蓝灯鱼提交您的身份信息（个人用户包括：姓名、 <b>身份证号码</b>；企业用户包括：企业名称、企业证件及信息、企业法定代表人信息等）。</li>
        <li>（2）如您通过企业对公账号方式进行实名认证的，您需向蓝灯鱼提交您的企业名称、社会信用代码、营业执照图片，以及 <b>银行账号信息（包括开户行、银行账号和开户人）</b>，蓝灯鱼将以您提交信息仅作为蓝灯鱼完成您的实名认证时的打款账号使用。</li>
        <li>（3）如果您通过人脸识别来进行实名认证的，您需要按照实名认证页面提示的操作方式，提供 <b>面部特征的生物识别信息和您身份证正、反面照片</b>，并授权我们通过国家权威可信身份认证机构进行信息核验。<b>请您知悉，我们仅会利用前述信息对您的身份进行核验并对核验结果进行记录与存储，而不会存储该等敏感个人信息并用于其他您未明确授权的目的</b>。
          <b>您知悉并了解，身份证件信息，以及根据您选择的身份认证方式，支付宝账号信息、银行账号信息或面部特征的生物识别信息为根据法律要求对您的真实身份进行认证所必需。
            上述信息包含您的敏感个人信息，一旦被泄露或非法使用可能导致您的人格尊严受到侵害或者人身、财产安全受到危害。请您审慎考虑采取何种方式进行身份验证。但如您拒绝向我们提供任何上述身份认证信息，则我们无法对您完成真实身份进行认证并向您提供相应的技术服务。</b></li>
      </ul>
      <p>3.2.2. 为帮助您完成订单支付，您需要向蓝灯鱼提供一些与完成交易相关联的信息，包括交易的产品及/或服务信息、交易金额、下单时间、订单编号、订单状态、支付方式、支付流水号、支付状态及其他反洗钱法律要求的必要信息，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。<b>如您拒绝向我们提供前述信息，我们将无法向您提供相应的技术服务。</b></p>
      <p>3.2.3. 在您注册完成蓝灯鱼账户并购买我们提供的技术服务后，您可要求我们为您开具和寄送发票。如您选择纸质发票的，您需要向我们提供您的发票收件地址，包括收件人姓名、详细地址、邮编、手机号码；如您选择电子发票的，您需要向我们提供您的电子邮箱地址。<b>如您拒绝向我们提供前述信息，我们将无法协助您完成发票开具或寄送。</b></p>
      <h2>3.3. 为向您提供安全稳定的蓝灯鱼服务</h2>
      <p>为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或蓝灯鱼相关协议、规则的情况，当您使用蓝灯鱼官网及小程序服务期间，我们可能使用您的账号信息、设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。<b>如您拒绝我们收集前述信息，我们将无法向您提供本服务。</b>具体地，我们会收集如下必要信息：</p>
      <p>3.3.1. 设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（如硬件型号、硬件序列号、软件列表、应用安装列表、软件安装列表、唯一设备识别码IMEI、设备MAC地址、SIM卡、IMSI信息、Android ID、MEID、Open UDID、GUID、BSSID、SSID、OAID、广播组件通讯信息、ICCID、运营商信息、传感器（陀螺仪、重力传感器、加速度传感器、环境传感器、光学心率传感器）、剪切板）。</p>
      <p>3.3.2. 日志信息：当您使用我们的客户端提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的 <b>网页记录</b>等。</p>
      <h2>3.4. 其他服务</h2>
      <p>我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，<b>但这些统计信息不包含您的任何身份识别信息。</b></p>
      <h2>4、为向您提供蓝灯鱼应用的附加功能或服务</h2>
      <h2>4.1. 为向您提供会员资料完善服务</h2>
      <p>在您完成蓝灯鱼应用账号注册后，您可以在账号“基本资料”中继续填写您的信息，包括（1）您的会员身份类型（个人、企业）、单位名称（如果您已完成实名认证，单位名称将会以您的实名认证信息为准）、营业年限、企业规模、从事行业、主营业务、主要产品、网站域名;（2）联系信息（国家、所在地区、街道、联系电话、传真）。您提供的本条第（1）类信息，会帮助我们更了解您对于蓝灯鱼服务需求，以便蓝灯鱼有针对性的向您推广、介绍产品；您提供的本条第（2）类信息，蓝灯鱼可能会利用如上方式向您推广、宣传产品、邮寄业务通知（含账单）或与您进行业务沟通。<b>如您拒绝向我们提供前述信息，可能无法享受我们向您提供的增值服务，但您仍可享受蓝灯鱼应用的基本功能或服务。</b></p>
      <h2>4.2. 为向您提供专业支持或帮助</h2>
      <p style=" text-indent: 2em;">当您在服务中心内请求我们支持或帮助时，您需要向我们提供问题描述，同时我们会需要收集您的咨询记录、报障记录和针对用户故障的排障过程（如 <b>通信或通话记录</b>），蓝灯鱼将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。<b>如您拒绝向蓝灯鱼提供前述信息，我们将可能无法向您提供相应支持或帮助，但不会影响您使用蓝灯鱼小程序基本功能和其他产品。</b></p>
      <p><b>请您注意，您在使用我们的产品和/或服务时由您主动提供、上传或发布的内容和信息（例如您发布的产品或服务评价信息），可能会泄露您的敏感个人信息，请您谨慎对待。如您在其他用户可见的公开区域内上传或发布的信息中、或者您对其他人上传或发布的信息作出的回应中公开您的个人信息，该等个人信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本政策披露的联系方式联系我们。</b></p>
      <h2>4.3. 我们基于系统权限为您提供的附加功能</h2>
      <p>当您使用蓝灯鱼小程序端时，为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加功能中可能会通过开启系统权限以收集和使用您的个人信息。<b>如果您不同意开启相关权限，不会影响您使用蓝灯鱼小程序的基本功能，但您可能无法获得这些附加功能给您带来的额外用户体验。</b></p>
      <p><b>您可在您设备的“设置”页面中或我们客户端“我的-设置-系统权限管理”中逐项查看权限的状态，可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</b></p>
      <h2>4.4. 为了提升您的产品和/或服务使用体验</h2>
      <p>4.4.1. 我们会不时地开展客户体验调研或服务沟通工作，我们可能通过您在账号注册环节、问卷填写环节、业务服务环节等过程中向我们提供联系信息与您取得联系。如您不希望参加调研或沟通，可以在接到来电后表示不希望参加调查，或者发送退订邮件，或拒绝填写在线问卷。如果您不想接收我们给您发送的商业性电子信息，您可通过短信提示回复退订或根据信息中提供的退订方式予以退订。</p>
      <p>4.4.2. 在您使用蓝灯鱼网站时为向您展示更符合您需求的信息，我们可能将来自蓝灯鱼网站某项服务的用户信息与来自其他项服务的信息结合起来，做出特征模型并进行用户画像，向您展示、推送信息和可能的商业广告，包括但不限于关于蓝灯鱼产品的新闻以及市场活动及优惠促销信息、蓝灯鱼合作第三方的推广信息，或其他您可能感兴趣的内容。同时，我们也会为您提供非针对您个性化需求的信息展示。</p>
      <p>4.4.3. 为让您享受到我们的提供的各项更符合您需求的服务，我们也可能会以短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动的商业性信息及其他商品或服务信息，或在您使用蓝灯鱼网站时通过合理频次的弹窗推送上述内容。您知悉蓝灯鱼小程序目前不提供任何定向推送的服务。如您不愿接收我们的商业性信息及其他商品或服务信息，您可以通过短信、电子邮件中提供的退订方式以及直接联系我们的客服进行退订或关闭，如您希望关闭弹窗推送信息，您也可以通过弹窗页面上的“跳过”按钮或相关按钮进行关闭。</p>
      <h2>5、从第三方获取信息的情形</h2>
      <p>5.1. 在法律允许的情况下，基于您的授权，为了向您提供更优化的服务，满足您的使用需求，我们可能会从我们的关联公司或合作单位（如生态合作伙伴等）处接收您的个人信息或其他信息。此外，我们也会收集到第三方使用我们的产品或服务时所提供有关您的共享个人信息。</p>
      <h2>6、无需获得您授权同意的个人信息收集、使用例外情形</h2>
      <p>6.1. 您充分知晓，依据可适用的法律，在以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：</p>
      <ul>
        <li>6.1.1. 涉及国家安全与利益、社会公共利益的；</li>
        <li>6.1.2. 为履行我们的法定职责或者法定义务或者响应政府部门指示所必需的；</li>
        <li>6.1.3. 与您签订和履行您作为协议或其他书面文件一方所必需的；</li>
        <li>6.1.4. 为应对突发公共卫生事件，或者紧急情况下为保护您和他人的生命健康和财产安全所必需；</li>
        <li>6.1.5. 在合理的范围内处理您自行向社会公众公开的个人信息；</li>
        <li>6.1.6. 在合理的范围内处理从合法公开披露的信息中收集的您的个人信息（如合法的新闻报道、政府信息公开等渠道）；</li>
        <li>6.1.7. 法律法规规定的其他情形。</li>
      </ul>
      <p><b>6.2. 请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未被包含在上述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告、另行签署协议等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。</b></p>
      <h2>7、我们如何共享、转让、公开披露您的用户信息</h2>
      <p>我们产品或/及服务中的某些模块和功能将由服务提供商提供，为此，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们在与该等合作伙伴进行合作时，将会遵守以下原则：（1）<b>合法正当与最小必要原则：</b>数据处理应当具有合法性基础，具有正当的目的，并以实现处理目的最小范围为限；（2）<b>用户知情权与决定权最大化原则：</b>数据处理过程中充分尊重用户对其个人信息处理享有的知情权与决定权；（3）<b>安全保障能力最强化原则：</b>我们将采取必要措施保障所处理个人信息的安全，审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作协议的有关要求。</p>
      <h2>7.1. 委托处理</h2>
      <p>我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。目前，我们委托的授权合作伙伴包括以下类型：</p>
      <ul>
        <li>7.1.1. <b>广告、分析服务类的授权合作伙伴</b>。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。</li>
        <li>7.1.2. <b>产品或服务的供应商和其他合作伙伴</b>。我们将信息委托给支持我们业务的供应商和其他合作伙伴处理，这些支持包括受我们委托提供的咨询、分析、营销、技术支持等专业服务。</li>
        <li>7.1.3. <b>支付类的授权合作伙伴</b>。为完成订单支付，我们会将您的账号信息、订单信息、<b>付款账户信息</b>以及其他完成支付的必要信息提供给第三方支付类授权合作伙伴。</li>
      </ul>
      <h2>7.2. 第三方共享</h2>
      <p>原则上，我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</p>
      <ul>
        <li>7.2.1. <b>在获取您明确同意的情况下共享：</b>获得您的明确同意后，我们会与其他方共享您的用户信息。</li>
        <li>7.2.2. <b>为履行蓝灯鱼法定义务所必需的共享：</b>我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息。</li>
        <li>7.2.3. <b>为订立、履行您作为一方当事人的合同所必需的情况下的共享：</b>例如在蓝灯鱼线上服务上创建的某一交易中，如交易一方履行或部分履行了交易义务并提出合理的信息披露请求的，蓝灯鱼有权决定向其提供交易对方的联络方式等必要信息包括您的蓝灯鱼账号、联系人姓名、联系电话、银行账号信息（开户行、银行账号、收款人）以促成交易的完成。</li>
      </ul>
      <h2>7.3. 转让</h2>
      <p>原则上，我们不会与其他组织和个人共享您的用户信息，但在蓝灯鱼与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。如涉及破产且无承接方的情形，我们将依法对数据进行删除处理。</p>
      <h2>7.4. 公开披露</h2>
      <p>我们仅会在以下情况下，公开披露您的用户信息：</p>
      <ul>
        <li>7.4.1. 获得您充分同意或基于您的主动选择，我们可能会公开披露您的用户信息；</li>
        <li>7.4.2. 为保护蓝灯鱼平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或蓝灯鱼平台相关协议、规则披露关于您的用户信息。</li>
      </ul>
      <h2>7.5. 对外提供用户信息时事先征得授权同意的例外</h2>
      <p>以下情形中，对外提供用户信息无需事先征得您的授权同意：</p>
      <ul>
        <li>7.5.1. 与国家安全、国防安全有关的；</li>
        <li>7.5.2. 与公共安全、公共卫生、重大公共利益有关的；</li>
        <li>7.5.3. 与刑事侦查、起诉、审判和判决执行等有关的；</li>
        <li>7.5.4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
        <li>7.5.5. 您自行向社会公众公开的个人信息；</li>
        <li>7.5.6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</li>
        <li>7.5.7. 为履行我们的法定职责或者法定义务或者响应政府部门指示所必需的。</li>
      </ul>
      <h2>8、用户业务数据和公开信息</h2>
      <p><b>不同于您的用户信息，对于用户业务数据和公开信息，蓝灯鱼将按如下方式处理：</b></p>
      <h2>8.1. 用户业务数据</h2>
      <ul>
        <li>8.1.1. 您通过蓝灯鱼提供的服务，生成、加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您用户业务数据的控制权。蓝灯鱼作为产品服务提供商，我们只会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。</li>
        <li>8.1.2. 您应对您的用户业务数据来源及内容负责，蓝灯鱼提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。</li>
        <li>8.1.3. 根据您与蓝灯鱼协商一致，蓝灯鱼在您选定的数据中心存储用户业务数据。蓝灯鱼恪守对用户的安全承诺，根据适用的法律保护用户存储在蓝灯鱼数据中心的数据。</li>
      </ul>
      <h2>8.2. 公开信息</h2>
      <p>8.2.1. 公开信息是指您公开分享的任何信息，任何人都可以在使用和未使用蓝灯鱼服务期间查看或访问这些信息。例如您在蓝灯鱼论坛发布的信息。</p>
      <p>8.2.2. 为使用蓝灯鱼服务，可能存在您必须公开分享的信息。 </p>
      <h2>9、您如何管理您的用户信息</h2>
      <h2>9.1. 查阅、更正和补充</h2>
      <p>9.1.1. 您可自行【登录蓝灯鱼网站-“个人中心”】查询、管理（变更、删除）使用蓝灯鱼服务时而提交的基本业务信息（基本资料）和联系人信息。</p>
      <p>9.1.2. 您也可自行【登录蓝灯鱼小程序-“我的”】查询、管理（变更、删除）使用蓝灯鱼服务时而提交的基本业务信息（基本资料）和联系人信息。</p>
      <h2>9.2. 复制</h2>
      <p style="text-indent: 2em;">您可以通过以下方式复制您的信息：</p>
      <ul>
        <li>9.2.1. 您可以通过相关路径查询后复制您的个人信息；</li>
        <li>9.2.1. 您可以通过相关路径查询后复制您的个人信息；</li>
      </ul>
      <h2>9.3. 删除</h2>
      <p>9.3.1. 您可以通过相关路径删除您的部分信息，或申请注销账户删除您的全部信息。</p>
      <p>9.3.2. 在以下情形中，您可以通过“个人中心”选择联系客服或选择拨打热线：010-5845 7888，向我们提出删除用户信息的请求：</p>
      <ul>
        <li>（1）如果我们处理用户信息的行为违反法律法规；</li>
        <li>（2）如果我们收集、使用您的用户信息，却未征得您的明确同意；</li>
        <li>（2）如果我们收集、使用您的用户信息，却未征得您的明确同意；</li>
      </ul>
      <p><b>9.3.3. 当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</b></p>
      <h2>9.4. 改变或撤回授权范围</h2>
      <p>9.4.1. 您可自行【登录蓝灯鱼网站-“个人中心”】改变或者收回您授权我们收集和处理您的个人信息的范围。</p>
      <p>9.4.2. 您也可自行【登录蓝灯鱼小程序-“我的”】改变或者收回您授权我们收集和处理您的个人信息的范围。</p>
      <p>9.4.3. 对于您无法直接通过上述方式设置的授权，您可以通过“我的-服务中心”选择联系客服或选择拨打热线：400-80-13260进行修改，但请注意，对于部分类型的个人信息，例如实现蓝灯鱼基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求。</p>
      <p><b>9.4.4. 当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响我们此前基于您的授权而开展的个人信息处理行为。</b></p>
      <h2>9.5. 注销账户</h2>
      <p>9.5.1. 您可自行【登录蓝灯鱼网站-“我的蓝灯鱼”个人中心】提交账号注销申请。</p>
      <p>9.5.2. 您也可自行【登录蓝灯鱼小程序-“我的”】提交账号注销申请。</p>
      <p>9.5.3. 在您主动注销账户之后，我们将停止为您提供产品或服务，并删除您的个人信息，或对其进行匿名化处理，但法律法规或行业监管规则另有规定的除外。</p>
      <h2>9.6. 响应您的上述请求</h2>
      <p>9.6.1. 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
      <p>9.6.2. 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们有权酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
      <p><b>在符合相关法律要求的情况下，您的近亲属经我们身份核实后，可以对您的相关个人信息行使上述查阅、更正、删除、复制等权利，但您另有安排的除外。</b></p>
      <h2>9.7. 响应您的权利请求的例外</h2>
      <p>以下情形中，我们有权不响应您的上述权利请求：</p>
      <ul>
        <li>9.7.1. 与我们履行法律法规规定的义务相关的；</li>
        <li>9.7.2. 与国家安全、国防安全直接相关的；</li>
        <li>9.7.3. 与公共安全、公共卫生、重大公共利益直接相关的；</li>
        <li>9.7.4. 与刑事侦查、起诉、审判和执行判决等直接相关的；</li>
        <li>9.7.5. 我们有充分证据表明您或其他个人信息主体存在主观恶意或滥用权利的；</li>
        <li>9.7.6. 出于维护您或其他个人信息主体的生命、财产等重大合法权益但又很难得到本人授权同意的；</li>
        <li>9.7.7. 响应您或其他个人信息主体的请求将导致您或其他个人信息主体、组织的合法权益受到严重损害的；</li>
        <li>9.7.8. 涉及商业秘密的。</li>
      </ul>
      <h2>10、我们如何使用 Cookie 和同类技术</h2>
      <p>10.1为确保正常运转、为您获得更轻松的访问体验、我们会在您的移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。蓝灯鱼只能读取蓝灯鱼提供的 Cookie 。</p>
      <p>10.2. 您可根据自己的偏好管理 Cookie ，您也可以清除计算机上保存的所有 Cookie 。大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
      <p>10.3. 除 Cookie 外，我们还会在小程序上使用网站信标和像素标签等其他同类技术。网站信标通常是一种嵌入到网页或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
      <h2>11、我们如何保护和保存您的用户信息</h2>
      <p>11.1. 蓝灯鱼非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。</p>
      <p>11.2. 我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非受到法律的允许。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。</p>
      <p><b>11.3. 尽管我们已经按照相关法律法规要求采取了合理有效的安全措施，但由于技术限制和可能存在的恶意手段，我们不能保证百分之百的信息安全。请您了解并理解，我们所提供的系统和通信网络可能会受到我们无法控制的因素影响而出现问题。因此，我们强烈建议您采取积极措施来保护个人信息的安全，例如使用复杂密码、定期更改密码、不将账号密码和相关个人信息透露给他人等。</b></p>
      <p>11.4. 在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通等等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。</p>
      <p>11.5. 如出现蓝灯鱼产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。</p>
      <p><b>11.6. 原则上，我们在中华人民共和国境内产生或收集的用户信息，将存储在中华人民共和国境内。</b>但是，为向您提供某些蓝灯鱼服务，在遵守和符合相关法律法规要求的前提下，我们有可能将必要的用户信息传输至境外。但我们会在具体场景下明确告知您境外接收方的名称、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使权利的方式和程序等事项，并取得您的单独同意（如适用）。我们会采取去标识化处理、加密传输、定期评估审计接收方的安全能力水平、签订数据处理协议/条款等必要措施，并根据相关法律法规的要求履行合规义务，包括向网信部门申报安全评估、申请个人信息保护认证或与境外接收方订立个人信息出境标准合同并向网信部门备案。</p>
      <p><b>11.7. 如第11.6.条所述，如您基于自身业务目的采购某些蓝灯鱼境外服务资源，可能会导致您的用户业务数据出境。对此，蓝灯鱼仍将采取充分的保护措施保障您的用户业务数据安全，但由于蓝灯鱼完全按照您的指示进行数据处理，您作为用户业务数据的完全控制方应承担上述的合规义务。如您对此有任何疑问，可通过本政策文末的联系方式与我们联系。</b></p>
      <h2>12、未成年人用户信息的特别约定</h2>
      <p><b>12.1. 我们主要面向成年人提供本网站服务及其他相关产品。但我们非常重视对未成年人个人信息的保护，如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策的所有内容，并在征得您的父母或其他监护人同意的前提下使用我们的产品或服务以及向我们提供信息。</b></p>
      <p><b>12.2. 请您知悉并理解，在实名认证环节，如果我们识别到您是未满14周岁的儿童，我们将无法向您提供认证服务。</b></p>
      <p>12.3. 受制于现有技术和商业模式，我们很难在账号注册环节主动识别未成年人的个人信息，如果您是未成年的监护人，且您发现我们存在未经其授权获取未成年人个人信息的情况，您可通过本政策中公示的联系方式联系我们，我们在收到通知后会及时予以核查，并在核查属实后及时删除或匿名化处理相关信息。如我们主动审查发现存在前述情形的，我们也会主动对相关个人信息予以删除或进行匿名化处理。</p>
      <h2>13、本政策的更新</h2>
      <p>13.1. 我们可能会对本政策进行不时地修订。</p>
      <p>13.2. 未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在公司网站上发布对本政策所做的任何修订。</p>
      <p>13.3. 对于重大修订，我们还会提供更为显著的通知（包括对于某些服务，我们会通过网站公示的方式进行通知甚至向您提供弹窗提示，说明本政策的具体变更内容）。</p>
      <p>13.4. 本政策所指的重大变更包括但不限于：</p>
      <ul>
        <li>13.4.1. 我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等。</li>
        <li>13.4.2. 我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。</li>
        <li>13.4.3. 用户信息共享、转让或公开披露的主要对象发生变化。</li>
        <li>13.4.4. 您参与用户信息处理方面的权利及其行使方式发生重大变化。</li>
        <li>13.4.5. 我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时。</li>
        <li>13.4.6. 用户信息安全影响评估报告表明存在高风险时。</li>
      </ul>
      <p>我们还会将本政策的历史版本在网站存档，供您查阅。</p>
      <h2>14、如何联系我们</h2>
      <p style="text-indent: 2em;">您对本政策内容有任何疑问和意见，或者您对蓝灯鱼对本政策的实践以及操作上有任何疑问和意见，您可以通过“我的-服务中心”选择联系客服或选择拨打热线：+86-10-58457888与我们联系，我们将在15天内回复您的请求。如您对我们的解决方式不满，您有权向中国互联网举报中心投诉。</p>
      <h2>附录：相关定义</h2>
      <p><b>蓝灯鱼：</b>除非另有约定，指北京蓝灯鱼智能科技有限公司（注册地址：北京市海淀区知春路甲48号3号楼3单元14层17C），公司网址是 www.lanternfish.cn 。</p>
      <p><b>蓝灯鱼网站：</b>指蓝灯鱼官网（域名为：lanternfish.cn），但蓝灯鱼国际站（包括 lanternfish-ai.com以及所有下属页面）除外。</p>
      <p><b>蓝灯鱼小程序：</b>指蓝灯鱼开发的“蓝灯鱼”微信小程序。</p>
      <p><b>用户信息：</b>用户信息是指您注册蓝灯鱼网站账户，使用我们的服务以及我们为了向您提供服务，您提交的或者我们收集的用户会员类信息，如姓名、联系方式等，具体请见本政策“2、我们如何收集和使用您的用户信息”中各项业务功能对应所需要收集与使用的信息。其中，部分用户信息属于个人信息及/或敏感个人信息。</p>
      <p><b>业务数据：</b>不同于用户信息，是指用户利用蓝灯鱼的服务上传、下载、分发等通过蓝灯鱼的技术服务处理的数据。</p>
      <p><b>设备信息：</b>包括硬件型号、硬件序列号、软件列表、应用安装列表、软件安装列表、唯一设备识别码IMEI、设备MAC地址、SIM卡、IMSI信息、Android ID、MEID、Open UDID、GUID、BSSID、SSID、蓝牙信息、广播组件通讯信息、ICCID、运营商信息、剪切板。以产品实际采集情况为准。</p>
      <p><b>个人信息：</b>指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息</p>
      <p><b>敏感个人信息：</b>敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息</p>
      <p><b>儿童：</b>指不满十四周岁的未成年人。</p>
      <p><b>去标识化：</b>指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</p>
      <p><b>匿名化：</b>指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
      <p><b>中国互联网举报中心网址：</b>https://www.12377.cn/。</p>
      <div style="text-align: right">
        <p style="text-align: end"><b>更新日期：2025年3月18日</b></p>
      </div>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
  article {
    box-sizing: border-box;
    h1 {
      text-align: center;
      /*font-weight: 400;*/
      color: #022144;
    }
    p {
      text-align: justify;
      color: #565d64;
      line-height: 1.6;
    }
    h2 {
      margin: 10px 0;
      color: #022144;
      /*font-size: 1.2rem;*/
    }
    ul {
      &.active {
        text-indent: 2em; /* 缩进 2 个字符（通常是 2 个汉字宽度） */
      }
      list-style: decimal;
      color: #656e77;
      li {
        text-align: justify;
      }
    }
    padding-bottom: 20px;
  }
</style>
